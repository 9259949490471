import {
	apiConstants,
	config,
	downloadBlob,
	mockResolveApiCall,
	requestOptions,
	requestTypes,
} from "../../Utilities/Api";


//************************************************** STANDARD API SERVER CALLS ****************************************

class ServerReportApi {
	static exportReport(reportSearchCriteria) {
		
		console.log(`${config.URL}reports/${reportSearchCriteria}/Export`);
		return downloadBlob(
			requestOptions(`${config.URL}reports/${reportSearchCriteria}/Export`,
				requestTypes.GET,
				null,
				apiConstants.successMessage.report.REPORT_DOWNLOADED));
	}

}

//************************************************** MOCK API SERVER CALLS ****************************************

class MockReportApi {
	static exportReport() {
		return mockResolveApiCall(true, apiConstants.successMessage.report.REPORT_DOWNLOADED);
	}

	static deleteFollowUpFiles() {
		return mockResolveApiCall(0, apiConstants.successMessage.report.FOLLOW_UP_DELETED);
	}

	
}

const reportApi = config.environment.MODE === config.modes.SERVER ? ServerReportApi : MockReportApi;
export {reportApi};
