import React from "react";
import PropTypes from "prop-types";
import {
    Button,
    convertInputFormToObject,
    InputForm, SelectField,
    TextField,
    useInputForm
} from "../../../Components/Inputs";
import {clearInputFormFields} from "../../../Components/Inputs/inputUtility";
import {GridCell, gridConstants, GridContainer} from "../../../Components/Grid";
import {FORMAT_LIST} from "../../Library/libraryConstants";
import { ORDER_TYPE_LIST} from "../OrderRequest/orderRequestConstants";
import SearchableSingleSelectField from "../../../Components/Inputs/SearchableSingleSelectField";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {jobTitles} from "../../../SiteConfig/jobTitles";

const OrderSearchFormView = ({
                                 buttonSharedSettings,
                                 districts,
                                 handleSetOrderSearchCriteria,
                                 isLoading,
                                 orderSearchCriteria,
                                 shippingLocations
                             }) => {

    const searchForm = useInputForm(orderSearchCriteria);

    const handleClearClick = (event) => {
        event.preventDefault();
        clearInputFormFields(searchForm);
        const updatedOrderSearchCriteria = {...orderSearchCriteria};
        updatedOrderSearchCriteria.ClearOrderSearch();
    };

    const handleSearchClick = (event) => {
        event.preventDefault();
        let criteria = convertInputFormToObject(searchForm);
        const updatedOrderSearchCriteria = {...orderSearchCriteria};
        updatedOrderSearchCriteria.Search(criteria);
        handleSetOrderSearchCriteria(updatedOrderSearchCriteria);
    };

    const inputSharedSettings = {
        large_columns: gridConstants.column.THREE,
        medium_columns: gridConstants.column.SIX,
    };

    return <>
        <InputForm name={"orderSearch"}>

            <SelectField
                {...searchForm.orderType}
                {...inputSharedSettings}
                includeDefaultOption={false}
                options={ORDER_TYPE_LIST}
            />

            <TextField
                {...searchForm.itemName}
                {...inputSharedSettings}
            />

            <SearchableSingleSelectField
                {...searchForm.shippingLocationId}
                {...inputSharedSettings}
                includeDefaultOption
                label={'Active Location List'}
                options={shippingLocations}
            />

            <TextField
                {...searchForm.studentName}
                {...inputSharedSettings}
            />

            <TextField
                {...searchForm.isbn}
                {...inputSharedSettings}
            />

            <SelectField
                {...searchForm.format}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"All"}
                includeDefaultOption
                options={FORMAT_LIST}
            />

            <SearchableSingleSelectField
                {...searchForm.districtId}
                {...inputSharedSettings}
                defaultOption={"All"}
                includeDefaultOption
                label={'District'}
                options={createListFromArrayOfObjects(districts, "id", "districtName")}
            />

            <SelectField
                {...searchForm.patronRole}
                {...inputSharedSettings}
                defaultOptionValue={""}
                defaultOption={"Select a Job Title"}
                includeDefaultOption
                label={'Job Title'}
                options={createListFromArrayOfObjects(jobTitles, "jobID", "jobTitle")}
            />

            <GridCell>
                <GridContainer isReverseOrder isFlush hasPaddingX>

                    <GridCell {...buttonSharedSettings}>
                        <Button
                            disabled={isLoading}
                            label="Search"
                            name="btnSearchOrder"
                            onClick={handleSearchClick}
                            isPrimary
                        />
                    </GridCell>

                    <GridCell {...buttonSharedSettings} medium_offset={gridConstants.column.SIX}>
                        <Button
                            disabled={isLoading}
                            name="btnClearOrderSearch"
                            onClick={handleClearClick}
                            label="Clear"
                        />
                    </GridCell>

                </GridContainer>
            </GridCell>

        </InputForm>
    </>;
};

OrderSearchFormView.propTypes = {
    buttonSharedSettings: PropTypes.object.isRequired,
    districts: PropTypes.array.isRequired,
    handleSetOrderSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    orderSearchCriteria: PropTypes.object.isRequired,
    shippingLocations: PropTypes.array.isRequired,
};

export default OrderSearchFormView;
