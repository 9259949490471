import PropTypes from "prop-types";
import React from "react";
import {PageTitle} from "../../Components/Landmarks";
import {locations} from "../../Utilities/Location";
import {Allow, policyEvents} from "../../Components/Authorize";
import {LayoutMedium} from "../../Components/Layout";
import {DashboardTile} from "./DashboardTile";
import {GridContainer} from "../../Components/Grid";

export const DashboardContainer = ({header = "Dashboard", message = ""}) => {

    return (
        <>
            <LayoutMedium>
                <PageTitle h1={header}/>
                {message && <p className="text-center">{message}</p>}

                <GridContainer hasMarginX hasMarginY className={`dashboard`}>
                    <DashboardTile
                        {...locations.LIBRARY_CATALOG.link}
                        description={"Search, view, update"}
                    />
                    <Allow policyEvent={policyEvents.PROCESS_LOANS}>
                        <DashboardTile
                            {...locations.LOAN_MANAGEMENT.link}
                            description={"Search, view, update"}
                        />
                    </Allow>
                    <Allow policyEvent={policyEvents.MANAGE_ORDER}>
                        <DashboardTile
                            {...locations.ORDER_MANAGEMENT.link}
                            description={"Manage orders and special orders"}
                        />
                    </Allow>
                    <Allow policyEvent={policyEvents.VIEW_STUDENTS}>
                        <DashboardTile
                            {...locations.STUDENT_MANAGEMENT.link}
                            description={"Search, view, update"}
                        />
                    </Allow>
                    <Allow policyEvent={policyEvents.APPROVE_USERS}>
                        <DashboardTile
                            {...locations.USER_MANAGEMENT.link}
                            description={"View and manage all users"}
                        />
                    </Allow>
                    <Allow policyEvent={policyEvents.ADVANCE_SEARCH}>
                        <DashboardTile
                            {...locations.REPORTS.link}
                            description={"View reports"}
                        />
                    </Allow>
                </GridContainer>

            </LayoutMedium>
        </>
    );
};

DashboardContainer.propTypes = {
    header: PropTypes.string,
    message: PropTypes.string,
    redirect: PropTypes.func.isRequired,
};
