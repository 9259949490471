import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import {emptyStudentQueueModel} from "./libraryFactory";
import {getFieldArrayFromObjectArrayByField, isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import libraryQueueApi from "./libraryQueueApi";
import {locations} from "../../Utilities/Location";
import {LibraryQueueForm} from "./LibraryQueueForm";
import {useInputForm} from "../../Components/Inputs";
import OrderRequestApi from "../Order/OrderRequest/orderRequestApi";
import {createListFromArrayOfObjects} from "../../Utilities/Types/listUtilities";
import {LibraryQueueList} from "./LibraryQueueList";

export const LibraryQueueContainer = ({
                                          handleApiCall,
                                          redirect
                                      }) => {

    const [queue, setQueue] = useState([]);
    const [students, setStudents] = useState();
    const [shippingLocations, setShippingLocations] = useState();
    const loanForm = useInputForm(emptyStudentQueueModel);
    const pendingQueue = queue.filter(q => q.student === null || q.shippingLocation === null);
    const isPendinQueueEmpty = isArrayNullOrEmpty(pendingQueue);
    const processingQueue = queue.filter(q => q.student !== null && q.shippingLocation !== null);
    const queueItems = getFieldArrayFromObjectArrayByField(pendingQueue, "queueItemId");

    const handleViewOrder = (orderId, catalogItemId) => redirect(locations.ORDER_VIEW.getLink([orderId, catalogItemId]).to);

    const handleGetQueue = () => {
        handleApiCall(libraryQueueApi.getQueue, (results) => setQueue(results));
    }

    const handleRemoveFromQueue = (queuedItemId) => {
        handleApiCall(() => libraryQueueApi.removeFromQueue(queuedItemId), handleGetQueue);
    }

    const handleAssignQueueItems = async (studentQueueViewModel) => {
        studentQueueViewModel.queueItems = queueItems;
        handleApiCall(() => libraryQueueApi.assignQueueItems(studentQueueViewModel), handleGetQueue);
    }

    useEffect(() => {

        const getStudents = () => {
            handleApiCall(() => OrderRequestApi.getStudents(), (results) => {
                setStudents(createListFromArrayOfObjects(results, `studentId`, `fullName`));
            });
        };

        const getShippingLocations = () => {
            handleApiCall(() => OrderRequestApi.getShippingLocations(), (results) => {
                setShippingLocations(createListFromArrayOfObjects(results, `shippingLocationId`, `name`));
            });
        }

        if (!shippingLocations)
            getShippingLocations();
        if (!students)
            getStudents();

        handleGetQueue();
    }, []);

    if (!shippingLocations || !students || !loanForm) return;

    return <>
        <PageTitle h1={`Loan Queue`}/>

        <LayoutFullBleed className={`catalog`}>
            {
                pendingQueue &&
                <LibraryQueueList
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    queue={pendingQueue}
                />
            }

            {
                !isPendinQueueEmpty &&
                <LibraryQueueForm
                    handleAssignQueueItems={handleAssignQueueItems}
                    loanForm={loanForm}
                    shippingLocations={shippingLocations}
                    students={students}
                />
            }
            {
                processingQueue &&
                <LibraryQueueList
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    handleViewOrder={handleViewOrder}
                    heading={`Processing`}
                    queue={processingQueue}
                />
            }
        </LayoutFullBleed>
    </>;
};

LibraryQueueContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    redirect: PropTypes.func.isRequired
};