import PropTypes from "prop-types";
import {PageTitle} from "../../../Components/Landmarks";
import React from "react";
import {ShippingLocationAddForm} from "./ShippingLocationAddForm";
import {shippingLocationModel} from "./shippingLocationFactory";
import ShippingLocationApi from "./shippingLocationApi";

export const ShippingLocationAddContainer = ({
                                                 handleApiCall,
                                                 handleReturnRedirect
                                             }) => {
    const handleReturn = () => handleReturnRedirect();

    const handleSaveShippingLocation = async (shippingLocationDetails) => {

        const shippingLocation = shippingLocationModel(shippingLocationDetails);

        handleApiCall(() => ShippingLocationApi.addShippingLocation(shippingLocation), () => {
            handleReturn();
        });
    };

    return <>
        <PageTitle h1={`Add Shipping Location`}/>
        <ShippingLocationAddForm
            handleSaveShippingLocation={handleSaveShippingLocation}
            handleReturn={handleReturn}/>
    </>;
};

ShippingLocationAddContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
    handleReturnRedirect: PropTypes.func.isRequired
};