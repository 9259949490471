import React from "react";
import PropTypes from "prop-types";
import {ORDER_TYPE} from "../OrderRequest/orderRequestConstants";
import {FORMAT_LIST} from "../../Library/libraryConstants";
import {Allow, policyEvents} from "../../../Components/Authorize";
import {ButtonIconProgress} from "../../../Components/Inputs/Buttons/ButtonIcon/ButtonIcon";

const OrderRow = ({redirect, order}) => {

    const handleSubmitProgressButtonClick = (event, orderId) => {
        event.preventDefault();
        console.log(redirect, orderId);
    };

    return (
        <tr id={order.id}>
            <td>
                {order.itemName}
            </td>
            <td>
                {order.shippingLocationName}
            </td>
            <td>
                {order.studentName}
            </td>
            <td>
                {order.publisherName}
            </td>
            <td>
                {order.isbn}
            </td>
            <td>
                {FORMAT_LIST.find(f => f.value === order.format)?.text}
            </td>
            <td>
                {order.orderType === ORDER_TYPE.Order ? `Order` : `Special Order Request`}
            </td>
            <td className={`align-center`}>
                <Allow policyEvent={policyEvents.MANAGE_ORDER}>
                    <ButtonIconProgress
                        inputDescription={`for ${order.itemName}`}
                        onClick={(event) => handleSubmitProgressButtonClick(event, order.id)}
                    />
                </Allow>
            </td>
        </tr>
    );
};

OrderRow.PropType = {
    redirect: PropTypes.func.isRequired,
    order: PropTypes.object.isRequired,
}

export default OrderRow;