import PropTypes from "prop-types";
import React from "react";
import {InputLayout} from "./InputLayout";
import {isNumber} from "../../Utilities/Types/typeUtilities";
import {helpTextSuffix, notificationSuffix} from "./inputConstants";

export class InputAdapter extends React.PureComponent {
    constructor(props) {
        super(props);

        this.onChange = this.onChange.bind(this);
        this.onInput = this.onInput.bind(this);
    }

    onChange(event) {
        if(this.onChange)
            this.props.onChange(event);
    }

    onInput(event) {
        this.onChange = null;
        this.props.onChange(event);
    }

    render() {
        let {
            disabled,
            helpText,
            hideLabel,
            inputType,
            label,
            labelClass,
            large_columns,
            max,
            medium_columns,
            min,
            name,
            placeholder,
            value="",
            error,
            responseFeedback,
            small_columns,
            step,
        } = this.props;

        if (isNumber(min) && !isNumber(max))
            label = `${label} (minimum: ${min})`;
        if (isNumber(max) && !isNumber(min))
            label = `${label} (maximum: ${max})`;
        if (isNumber(min) && isNumber(max))
            label = `${label} (range: ${min} - ${max})`;

        const labelClasses = hideLabel ? `${labelClass} is-visuallyhidden` : labelClass;

        return (
            <InputLayout
                disabled={disabled}
                error={error}
                helpText={helpText}
                label={label}
                labelClass={labelClasses}
                large_columns={large_columns}
                medium_columns={medium_columns}
                name={name}
                responseFeedback={responseFeedback}
                small_columns={small_columns}
            >
                <input
                    aria-disabled={disabled}
                    aria-invalid={!!error}
                    aria-errormessage={`${name}${notificationSuffix}`}
                    aria-describedby={`${name}${helpTextSuffix}`}
                    data-testid={name}
                    disabled={disabled}
                    id={name}
                    min={min}
                    max={max}
                    name={name}
                    onChange={this.onChange}
                    onInput={this.onInput}
                    placeholder={placeholder}
                    step={step}
                    type={inputType}
                    value={value || ""}
                />
            </InputLayout>
        );
    }
}

export const inputProps = {
    disabled: PropTypes.bool,
    error: PropTypes.string,
    helpText: PropTypes.string,
    hideLabel: PropTypes.bool,
    label: PropTypes.string.isRequired,
    labelClass: PropTypes.string,
    large_columns: PropTypes.string,
    medium_columns: PropTypes.string,
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func,
    placeholder: PropTypes.string,
    responseFeedback: PropTypes.string,
    small_columns: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

export const numberProps = {
    ...inputProps,
    decimalPlaces: PropTypes.number,
    isInline: PropTypes.bool,
    isPositiveNumberOnly: PropTypes.bool,
    isWholeNumberOnly: PropTypes.bool,
    maxValue: PropTypes.number,
    minValue: PropTypes.number,
}

InputAdapter.propTypes = {
    ...numberProps,
    inputType: PropTypes.string,
    step: PropTypes.string,
};

InputAdapter.defaultProps = {
    onChange: () => {
    },
    value: "",
};