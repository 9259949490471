import PropTypes from "prop-types";
import React from "react";
import {loanQueueModel} from "../Library/libraryFactory";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";
import {LoanCatalogItem} from "./LoanCatalogItem";

export const LoanQueueList = ({
                                  handleLoanQueueItem,
                                  handleRemoveFromQueue,
                                  heading = "Queue",
                                  queue,
                              }) => {
    const isQueueEmpty = isArrayNullOrEmpty(queue);

    return <>
        <header>
            <h2>{heading}</h2>
        </header>

        {
            !isQueueEmpty && queue &&
            queue.map((queuedItem) => {
                const {catalogItem, queueItemId} = queuedItem;
                const queueModel = loanQueueModel({...catalogItem, ...queuedItem});
                const handleDelete = () => handleRemoveFromQueue(queueItemId);
                const handleLoan = () => handleLoanQueueItem(queueItemId);
                return <LoanCatalogItem
                    key={queueItemId}
                    {...queueModel}
                    handleDelete={handleDelete}
                    handleLoan={handleLoan}
                />;
            })
        }
        {
            isQueueEmpty &&
            <p className={`text-center`}>No results.</p>
        }
        <br/>
        <br/>
    </>;
};

LoanQueueList.propTypes = {
    handleLoanQueueItem: PropTypes.func.isRequired,
    handleRemoveFromQueue: PropTypes.func.isRequired,
    heading: PropTypes.string,
    queue: PropTypes.array.isRequired,
};