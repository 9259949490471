import {createListFromObject} from "../../../Utilities/Types/listUtilities";

export const ORDER_TYPE = {
    Order : 0,
    SpecialOrderRequest : 1,
};

export const ORDER_TYPE_LIST = createListFromObject(ORDER_TYPE);


export const ORDER_STATUS = {
    "OrderReceived": 5,
    "OrderSentToVendor": 6,
    "OrderReceivedFromVendor": 7,
    "OrderPartiallyMailed": 8,
    "OrderFullyMailed": 9,
    "OrderCanceled": 10
};

export const ORDER_STATUS_LIST = createListFromObject(ORDER_STATUS);





