export const shippingLocationModel = ({
                                          name,
                                          address,
                                          city,
                                          state,
                                          zipcode
                                      } = {}) => ({
    name,
    address,
    city,
    state,
    zipcode
});