import React from 'react'
import {reportApi} from "./reportApi";
import { PageTitle } from '../../Components/Landmarks';
import ReportsFormView from './ReportsFormView';
import PropTypes from 'prop-types';


export const ReportsContainer = ({
    isLoading,
    handleApiCall,
}) => {

    const handleExportReport = (event) => {
        event.preventDefault();
        const reportSearchCriteria = 'AllStudentData'; // Example report type

        handleApiCall(() => reportApi.exportReport(reportSearchCriteria));
    };

  return ( <>

   <PageTitle h1={`Reports`} className={`h2`}/>
    <ReportsFormView 
        handleExportReport={handleExportReport}
        isLoading={isLoading}
    />
  
  </>
   
  )
}

ReportsContainer.propTypes = {
    isLoading: PropTypes.bool,
    reportSearchCriteria: PropTypes.object,
    handleApiCall: PropTypes.func
}

