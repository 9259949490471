import PropTypes from "prop-types";
import React from "react";
import {catalogQueueModel} from "./libraryFactory";
import {LibraryCatalogItem} from "./LibraryCatalogItem";
import {isArrayNullOrEmpty} from "../../Utilities/Types/arrayUtilities";

export const LibraryQueueList = ({
                                     handleRemoveFromQueue,
                                     handleViewOrder,
                                     heading = "Pending",
                                     queue,
                                 }) => {
    const isQueueEmpty = isArrayNullOrEmpty(queue);

    return <>
        <header>
            <h2>{heading}</h2>
        </header>

        {
            !isQueueEmpty && queue &&
            queue.map((queuedItem) => {
                const {catalogItem, queueItemId } = queuedItem;
                console.log("queuedItem", queuedItem);
                const queueModel = catalogQueueModel({...catalogItem, ...queuedItem});
                console.log("queueModel", queueModel);
                console.log("handleViewOrder", handleViewOrder);
                const handleDelete = () => handleRemoveFromQueue(queueItemId)
                return <LibraryCatalogItem
                    key={queueItemId}
                    {...queueModel}
                    handleDelete={handleDelete}
                    handleViewOrder={handleViewOrder}
                />;
            })
        }
        {
            isQueueEmpty &&
            <p className={`text-center`}>No results.</p>
        }
        <br/>
        <br/>
    </>;
};

LibraryQueueList.propTypes = {
    handleRemoveFromQueue: PropTypes.func.isRequired,
    handleViewOrder: PropTypes.func.isRequired,
    heading: PropTypes.string,
    queue: PropTypes.array.isRequired,
};