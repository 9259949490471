import React from "react";
import PropTypes from "prop-types";
import {GridTable} from "../../../Components/Grid";
import {isArrayNullOrEmpty} from "../../../Utilities/Types/arrayUtilities";
import OrderRow from "./OrderRow";

const OrderManagementView = ({
                                 handleSetOrderSearchCriteria,
                                 orderSearchCriteria,
                                 orders,
                                 redirect,
                             }) => {

    const handleOrderClick = (event) => {
        event.preventDefault();
        let criteria = {...orderSearchCriteria};
        criteria.UpdateSorting(event.target.dataset.id);
        criteria.loadSearchResults = false;
        handleSetOrderSearchCriteria(criteria);
    };

    const noResults = isArrayNullOrEmpty(orders);

    return <>
        <GridTable parentClass={`margin--topNone`}>
            <thead>
            <tr>
                <th onClick={handleOrderClick} className="is-clickable" data-id="itemName">Item Name</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="shippingLocationName">Shipping Location</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="studentName">Student</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="publisherName">Publisher</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="isbn">Isbn</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="format">Format</th>
                <th onClick={handleOrderClick} className="is-clickable" data-id="orderType">Order Type</th>
                 <th scope={"col"} className={"align-center"}>
                    Actions
                </th>
            </tr>
            </thead>
            <tbody>
            {
                !noResults &&
                orders.map((order, index) => {
                        return (<OrderRow
                            key={index}
                            redirect={redirect}
                            order={order}
                        />);
                    }
                )
            }
            {
                noResults &&
                <tr data-testid={`no-order-row`}>
                    <td colSpan={11} className={`text-center`}>No results found.</td>
                </tr>
            }
            </tbody>
        </GridTable>
    </>;
}

OrderManagementView.propTypes = {
    handleSetOrderSearchCriteria: PropTypes.func.isRequired,
    orderSearchCriteria: PropTypes.object,
    orders: PropTypes.array,
    redirect: PropTypes.func.isRequired,
}

export default OrderManagementView;





