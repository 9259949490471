export const order = ({
                                 orderId,
                                 alternateCopyright,
                                 alternateFormat,
                                 author,
                                 catalogFormatId,
                                 copyright,
                                 format,
                                 gradeLevel,
                                 isbn,
                                 itemName,
                                 needFrom,
                                 needTo,
                                 orderType, //= ORDER_TYPE.SpecialOrderRequest,
                                 publisherId,
                                 publisherName,
                                 queueStatus,
                                 seriesTitle,
                                 shippingLocationName,
                                 shippingLocationId,
                                 specialInstructions,
                                 studentId,
                                 studentName
                             } = {}) => ({
    orderId,
    alternateCopyright,
    alternateFormat,
    author,
    catalogFormatId,
    copyright,
    format,
    gradeLevel,
    isbn,
    itemName,
    needFrom,
    needTo,
    orderType,
    publisherId,
    publisherName,
    queueStatus,
    seriesTitle,
    shippingLocationName,
    shippingLocationId,
    specialInstructions: specialInstructions || ``,
    studentId,
    studentName
});

