import {apiConstants, config, requestOptions, requestTypes, sendRequest} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************
class serverShippingLocationApi {

    static addShippingLocation(shippingLocationModel) {
        return sendRequest(
            requestOptions(`${config.URL}shipping-locations`,
                requestTypes.POST,
                JSON.stringify(shippingLocationModel),
                apiConstants.successMessage.shippingLocation.ADD
            ),
        );
    }

    static removeShippingLocation(shippingLocationId) {

        return sendRequest(
            requestOptions(config.URL + `shipping-locations/${shippingLocationId}`,
                requestTypes.DELETE,
                null,
                apiConstants.successMessage.shippingLocation.DELETE
            ),
        );
    }
}

const shippingLocationApi = config.environment.MODE === config.modes.SERVER ? serverShippingLocationApi : serverShippingLocationApi;
export default shippingLocationApi;