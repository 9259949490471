import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "../../Components/Display";
import {Button, ButtonLinkStyle} from "../../Components/Inputs";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";
import {Allow, policyEvents} from "../../Components/Authorize";
import {getLoanStatusTextByValue} from "./loanUtility";
import {LOAN_STATUS} from "./loanConstants";

export const LoanCatalogItem = ({
                                    catalogId,
                                    details,
                                    handleDelete,
                                    handleEdit,
                                    handleLoan,
                                    loanStatus,
                                    shipping,
                                    title,
                                }) => {
    console.log("loanStatus", loanStatus);

    const loanStatusText = getLoanStatusTextByValue(loanStatus);

    const handleClickEdit = (catalogId, format) => handleEdit(catalogId, format);

    return <>
        <GridContainer className={`catalog__item`} hasMarginX>
            <GridCell>
                <h3 id={`title${catalogId}`}>
                    {title + " "}
                    {
                        <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                            <ButtonLinkStyle isCollapsed label={`(Edit Item)`}
                                             name={`btnEdit${catalogId}`}
                                             onClick={() => handleClickEdit(catalogId)}/>
                        </Allow>
                    }
                </h3>


                <GridContainer>
                    <GridCell medium_columns={gridConstants.column.SIX}>
                        <HtmlDisplay html={details}/>
                    </GridCell>
                    <GridCell medium_columns={gridConstants.column.SIX}>
                        <HtmlDisplay html={shipping}/>
                    </GridCell>
                </GridContainer>



                {
                    loanStatus === LOAN_STATUS.ApproveForCheckout &&
                    handleDelete &&
                    <Button isCollapsed label={`Remove`} name={`btnDelete${catalogId}`} onClick={handleDelete}/>
                }
                {
                    handleLoan &&
                    <Button isCollapsed isPrimary label={loanStatusText} name={`btnLoan${catalogId}`} onClick={handleLoan}/>
                }


            </GridCell>
        </GridContainer>
    </>;
};

LoanCatalogItem.propTypes = {
    details: PropTypes.string,
    catalogId: PropTypes.string,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleLoan: PropTypes.func,
    loanStatus: PropTypes.number,
    shipping: PropTypes.string,
    title: PropTypes.string,
};