import React from 'react'
import {Button} from '../../Components/Inputs'

const ReportsFormView = ({
    isLoading,
    handleExportReport,
}) => {
    console.log(isLoading);
  return (

    <Button
    name='exportReport'
    onClick={handleExportReport}
    label={"Export Report"}
    

    >
    </Button>
    
  )
}

export default ReportsFormView