import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {PageTitle} from "../../Components/Landmarks";
import {LayoutFullBleed} from "../../Components/Layout";
import loanApi from "./loanApi";
import {LoanQueueList} from "./LoanQueueList";
import libraryQueueApi from "../Library/libraryQueueApi";

export const LoanProcessingContainer = ({
                                          handleApiCall,
                                      }) => {

    const [queue, setQueue] = useState([]);

    const handleGetQueue = () => {
        handleApiCall(loanApi.getQueue, (results) => setQueue(results));
    }

    const handleRemoveFromQueue = (queuedItemId) => {
        handleApiCall(() => libraryQueueApi.removeFromQueue(queuedItemId), handleGetQueue);
    }

    const handleLoanQueueItem = (queuedItemId) => {
        handleApiCall(() => loanApi.loan(queuedItemId), handleGetQueue);
    }

    useEffect(() => {
        handleGetQueue();
    }, []);

    return <>
        <PageTitle h1={`Manage Loans`}/>

        <LayoutFullBleed className={`catalog`}>
            {
                queue &&
                <LoanQueueList
                    handleLoanQueueItem={handleLoanQueueItem}
                    handleRemoveFromQueue={handleRemoveFromQueue}
                    handleViewOrder={()=>{}}
                    queue={queue}
                />
            }
        </LayoutFullBleed>
    </>;
};

LoanProcessingContainer.propTypes = {
    handleApiCall: PropTypes.func.isRequired,
};