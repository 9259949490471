import PropTypes from "prop-types";
import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields, convertInputFormToObject,
    InputForm,
    inputFormIsValid,
    useInputForm
} from "../../../Components/Inputs";
import {GridCell} from "../../../Components/Grid";
import React from "react";
import {OrderRequestForm} from "./OrderRequestForm";
import {isStartBeforeEnd} from "../../../Utilities/Types/dateUtilities";
import {ORDER_STATUS, ORDER_TYPE} from "./orderRequestConstants";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";

export const OrderRequestView = ({
                                     shippingLocations,
                                     shippingLocationsDetails,
                                     publishers,
                                     handleAddLocation,
                                     handleCancelOrder,
                                     handleRemoveLocation,
                                     handleReturn,
                                     handleSubmitOrderRequest,
                                     students,
                                     orderRequestDetails,
                                     userId,
                                     viewOnly
                                 }) => {

    const orderRequestForm = useInputForm(orderRequestDetails);

    const handleCancelOrderClick = () => handleCancelOrder();


    const handleSubmitClick = () => {
        clearInputFormErrorFields(orderRequestForm);

        const isNeedFromAfterNeedToDate = !isStartBeforeEnd(orderRequestForm.needFrom.value, orderRequestForm.needTo.value)
        const verifyFromAndToDate = orderRequestForm.needFrom.value && orderRequestForm.needTo.value;
        if (verifyFromAndToDate && isNeedFromAfterNeedToDate) {
            orderRequestForm.needFrom.setError("Item need from date must be before Item need to date.");
            orderRequestForm.needTo.setError("Item need to date must be after Item need from date.");
        }

        checkAndSetError([
            orderRequestForm.shippingLocationId,
            orderRequestForm.studentId,
            orderRequestForm.itemName,
            orderRequestForm.isbn,
            orderRequestForm.format,
            orderRequestForm.copyright,
        ]);

        if (orderRequestDetails.orderType === ORDER_TYPE.SpecialOrderRequest)
            checkAndSetError([orderRequestForm.needFrom]);

        if (inputFormIsValid(orderRequestForm)) {
            const orderRequest = convertInputFormToObject(orderRequestForm);
            handleSubmitOrderRequest(orderRequest);
        }
    }

    const handleAddLocationClick = () => handleAddLocation();


    const handleRemoveLocationClick = () => {
        handleRemoveLocation(orderRequestForm.shippingLocationId.value);
        orderRequestForm.shippingLocationId.value = ``;
    }

    return <>
        <InputForm name={`orderRequestForm`}>

            <OrderRequestForm
                handleAddLocationClick={handleAddLocationClick}
                handleRemoveLocationClick={handleRemoveLocationClick}
                orderRequestForm={orderRequestForm}
                shippingLocations={shippingLocations}
                shippingLocationsDetails={shippingLocationsDetails}
                publishers={publishers}
                students={students}
                userId={userId}
                viewOnly={viewOnly}/>

            <GridCell className={`text-center`}>
                {
                    !viewOnly &&
                    <Button
                        isPrimary isCollapsed
                        label={'Save'}
                        name={`btnSubmit`}
                        onClick={handleSubmitClick}/>
                }
                {
                    !isNullOrUndefined(orderRequestDetails.queueStatus) && orderRequestDetails.queueStatus.toString() === ORDER_STATUS.OrderReceived.toString() &&
                    <Button
                        isPrimary isCollapsed
                        label={'Cancel Order'}
                        name={`btnCancelOrder`}
                        onClick={handleCancelOrderClick}/>
                }

                <Button
                    isCollapsed
                    label={'Go Back'}
                    name={`btnGoBack`}
                    onClick={handleReturn}/>
            </GridCell>
        </InputForm>
    </>;

};

OrderRequestView.propTypes = {
    shippingLocations: PropTypes.array.isRequired,
    shippingLocationsDetails: PropTypes.array.isRequired,
    publishers: PropTypes.array.isRequired,
    handleAddLocation: PropTypes.func.isRequired,
    handleCancelOrder: PropTypes.func.isRequired,
    handleRemoveLocation: PropTypes.func.isRequired,
    handleReturn: PropTypes.func.isRequired,
    handleSubmitOrderRequest: PropTypes.func.isRequired,
    orderRequestDetails: PropTypes.object.isRequired,
    students: PropTypes.array.isRequired,
    userId: PropTypes.string.isRequired,
    viewOnly: PropTypes.bool,
};