import {
    apiConstants,
    config,
    requestOptions,
    requestTypes,
    sendRequest,
} from "../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************

class serverQueueApi {
    static getQueue()
    {
        return sendRequest(
            requestOptions(config.URL + `loan/`,
                requestTypes.GET,
            ),
        );
    }
    static loan(queueId)
    {
        console.log("load api call");
        return sendRequest(
            requestOptions(config.URL + `loan/` + queueId,
                requestTypes.POST,
                null,
                apiConstants.successMessage.loan.SHIPPED,
            ),
        );
    }
}

const libraryQueueApi = config.environment.MODE === config.modes.SERVER ? serverQueueApi : serverQueueApi;
export default libraryQueueApi;