export const VIEW = "VIEW";
export const ADVANCE_SEARCH = "ADVANCE_SEARCH";
export const DELETE = "DELETE";
export const APPROVE_USERS = "APPROVE_USERS";
export const DELEGATE_USERS = "DELEGATE_USERS";
export const APPROVE_STUDENTS = "APPROVE_STUDENTS";
export const VIEW_STUDENT_HISTORY = "VIEW_STUDENT_HISTORY";
export const MANAGE_CATALOG = "MANAGE_CATALOG";
export const MANAGE_ORDER = "MANAGE_ORDER";
export const VIEW_STUDENTS = "VIEW_STUDENTS";
export const PROCESS_LOANS = "MANAGE_CATALOG";

