import {getGraveLevelTextByValue, getQueueStatusTextByValue} from "./libraryUtility";
import {isArray} from "../../Utilities/Types/typeUtilities";
import {isNullOrUndefined} from "../../Utilities/commonUtilities";

export const resourceModel = ({
                                  author = "",
                                  catalogId,
                                  copyright,
                                  description = "",
                                  formats,
                                  gradeLevel,
                                  isbn10,
                                  isbn13,
                                  numberOfCopiesToAdd = 0,
                                  previewIsbn,
                                  title = "",
                                  whatsNewEndDate,
                                  whatsNewStartDate,
                              } = {}) => ({
    author,
    catalogId,
    copyright,
    description,
    formats,
    gradeLevel,
    isbn10,
    isbn13,
    numberOfCopiesToAdd,
    previewIsbn,
    title,
    whatsNewEndDate,
    whatsNewStartDate,
});

const testResource = {
    author: "Test Author",
    copyright: "01/06/1981",
    description: "test description",
    gradeLevel: "2",
    isbn10: "1234567890",
    isbn13: "1234567890123",
    title: "test title",
};
export const testResourceModel = resourceModel(testResource);
export const emptyResourceModel = resourceModel();


export const formatModel = ({
                                availability = "",
                                catalogFormatId = "",
                                copies = [],
                                cost = 0,
                                format = 0,
                                formatName = "",
                                fundingSource = 0,
                                numberOfCopiesAvailable = 0,
                                numberOfCopiesToAdd = 0,
                                numberOfVolumes,
                                publisher = "",
                                publisherId,
                                purchaseOrderNumber = "",
                                shelfLocation = "",
                                vendor = "",
                                vendorId,
                            } = {}) => ({
    availability,
    catalogFormatId,
    copies,
    cost,
    format,
    formatName,
    fundingSource,
    numberOfCopiesAvailable,
    numberOfCopiesToAdd,
    numberOfVolumes: numberOfVolumes || 1,
    publisher,
    publisherId,
    purchaseOrderNumber,
    shelfLocation,
    vendor,
    vendorId,
});
export const emptyFormatModel = formatModel();

export const publisherModel = ({
                                   publisherId,
                                   name,
                               } = {}) => ({
    publisherId,
    name,
});
const testPublisher = {
    name: "Test Publisher",
};
export const emptyPublisherModel = publisherModel();
export const testPublisherModel = publisherModel(testPublisher);


export const vendorModel = ({
                                vendorId,
                                name,
                                email,
                                contactName,
                                phoneNumber,
                                faxNumber,
                                address,
                                city,
                                state,
                                zipCode,
                                website,
                            } = {}) => ({
    vendorId,
    name,
    email,
    contactName,
    phoneNumber,
    faxNumber,
    address,
    city,
    state,
    zipCode,
    website,
});
const testVendor = {
    name: "Test Vendor",
    email: "Vendor@vending.com",
    contactName: "Contact Vendor",
    phoneNumber: "123-456-7890",
    faxNumber: "098-765-4321",
    address: "123 Vending St",
    city: "Vendoropolis",
    state: "VN",
    zipCode: "12345",
    website: "www.vending.com",
};
export const emptyVendorModel = vendorModel();
export const testVendorModel = vendorModel(testVendor);


function createDetails({author, formats, gradeLevel, queueStatus, student}) {
    let details = "";

    const hasAuthor = !isNullOrUndefined(author);
    const hasFormats = isArray(formats);
    const hasGradeLevel = !isNullOrUndefined(gradeLevel);
    const hasQueueStatus = !isNullOrUndefined(queueStatus);
    const hasStudent = !isNullOrUndefined(student);

    details += hasAuthor ? `<div><b>Author:</b> ${author}</div>` : "";
    details += hasFormats ? `<div><b>Format:</b> ${formats[0]?.formatName}</div>` : "";
    details += hasGradeLevel ? `<div><b>Grade Level:</b> ${getGraveLevelTextByValue(gradeLevel)}</div>` : "";
    details += hasQueueStatus ? `<div><b>Queue Status:</b> ${getQueueStatusTextByValue(queueStatus)}</div>` : "";
    details += hasStudent ? `<div><b>Student:</b> ${createStudent(student)}</div>` : "";
    details += hasAuthor || hasFormats || hasGradeLevel || hasQueueStatus ? `<div class="margin--bottom"></div>` : '';
    return details;
}

function createShipping({patron, shippingLocation, student}) {
    let shipping = "";

    const hasPatron = !isNullOrUndefined(patron);
    const hasShippingLocation = !isNullOrUndefined(shippingLocation);
    const hasStudent = !isNullOrUndefined(student);
    shipping += hasStudent ? `<div>${createStudent(student)}</div>` : "";
    shipping += hasPatron ? `<div>${createPatron(patron)}</div>` : "";
    shipping += hasShippingLocation ? `<div>${createShippingLocation(shippingLocation)}</div>` : "";
    shipping += hasStudent || hasPatron || hasShippingLocation ? `<div class="margin--bottom"></div>` : '';
    return shipping;
}

function createStudent(student) {
    return `${student.firstName || ""} ${student.middleName || ""} ${student.lastName || ""}`;
}

function createPatron(patron) {
    return `C/O: ${patron.firstName || ""} ${patron.middleName || ""} ${patron.lastName || ""}`;
}

function createShippingLocation(shippingLocation) {
    let location = "";
    location += `<div>${shippingLocation.name}</div>`;
    location += `<div>${shippingLocation.address}</div>`;
    location += `<div>${shippingLocation.city}, ${shippingLocation.state} ${shippingLocation.zipCode}</div>`;
    return location;
}
export const catalogItemModel = ({
                                     author,
                                     catalogId,
                                     description,
                                     formats,
                                     gradeLevel,
                                     title,
                                 } = {}) => ({
    catalogId,
    description: description ? `<p>${description}</p>` : "",
    details: createDetails({author, gradeLevel}),
    formats,
    title,
});


export const catalogQueueModel = ({
                                      author,
                                      catalogId,
                                      formats,
                                      gradeLevel,
                                      orderId,
                                      orderType,
                                      queueStatus,
                                      student,
                                      title,
                                  } = {}) => ({
    catalogId,
    details: createDetails({author, formats, gradeLevel, queueStatus, student}),
    orderId,
    orderType,
    title,
});

export const studentQueueModel = ({
                                      needFrom,
                                      needTo,
                                      queueItems,
                                      shippingLocationId = "",
                                      studentId = "",
                                  } = {}) => ({
    needFrom,
    needTo,
    queueItems,
    shippingLocationId,
    studentId,
});
export const emptyStudentQueueModel = studentQueueModel();

export const loanQueueModel = ({
                                   author,
                                   catalogId,
                                   formats,
                                   gradeLevel,
                                   loanStatus,
                                   patron,
                                   queueStatus,
                                   shippingLocation,
                                   student,
                                   title,
                               } = {}) => ({
    catalogId,
    details: createDetails({author, formats, gradeLevel, queueStatus}),
    loanStatus: loanStatus || 0,
    shipping: createShipping({patron, shippingLocation, student}),
    title,
});
