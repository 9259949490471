import {createArrayFromObject} from "../Types/arrayUtilities";
import {createLocation} from "./locationUtilities";

export const locationBehaviors = {
    doNotRedirectBack: "doNotRedirectBack",
    redirectToDashboard: "redirectToDashboardIfAuthenticated",
    requiresAuthentication: "requiresAuthentication",
    requiresAdminAuthentication: "requiresAdminAuthentication",
};

export const params = {
    libraryCatalogFormatId: ":catalogFormatId",
    libraryCatalogItemId: ":catalogItemId",
    libraryPublisherId: ":libraryPublisherId",
    libraryVendorId: ":libraryVendorId",
    libraryCatalogFormat: ":format",
    orderId: ":orderId",
    patronId: ":patronId",
    studentId: ":studentId",
    updatePasswordToken: ":updatePasswordToken",
}

export const locations = {

    // GENERAL LOCATIONS
    LANDING: createLocation(() => `/home`, `Home`, []),
    REGISTER: createLocation(() => `/register`, `Register`, []),
    SIGN_IN: createLocation(() => `/sign-in`, `Sign in`, [locationBehaviors.redirectToDashboard]),
    SIGN_OUT: createLocation(() => `/goodbye`, `Sign out`, [locationBehaviors.doNotRedirectBack]),
    DASHBOARD: createLocation(() => `/dashboard`, `Dashboard`, [locationBehaviors.requiresAuthentication]),

    //Library
    LIBRARY_CATALOG: createLocation(() => `/library-catalog`, `Library Catalog`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_FORMAT: createLocation((catalogItemId = params.libraryCatalogItemId, catalogFormatId = params.libraryCatalogFormatId) => `/library-item/${catalogItemId}/library-format/${catalogFormatId}`, `Library Item`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_ITEM: createLocation((catalogItemId = params.libraryCatalogItemId) => `/library-item/${catalogItemId}`, `Library Item`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_PUBLISHER: createLocation((libraryPublisherId = params.libraryPublisherId) => `/library-publisher/${libraryPublisherId}`, `Manage Publishers`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_QUEUE: createLocation(() => `/library-queue/`, `Queue`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_VENDOR: createLocation((libraryVendorId = params.libraryVendorId) => `/library-vendor/${libraryVendorId}`, `Manage Vendors`, [locationBehaviors.requiresAuthentication]),
    LIBRARY_WHATS_NEW: createLocation(() => `/library-new`, `Whats New`, [locationBehaviors.requiresAuthentication]),

    //Loan
    LOAN_MANAGEMENT: createLocation(() => `/loans/`, `Manage Loans`, [locationBehaviors.requiresAuthentication]),

    //Order
    ORDER_REQUEST: createLocation((catalogItemId = params.libraryCatalogItemId, catalogFormatId = params.libraryCatalogFormatId) => `/order-request/${catalogItemId}/${catalogFormatId}`, `Order Request`, [locationBehaviors.requiresAuthentication]),
    ORDER_VIEW: createLocation((orderId = params.orderId, catalogItemId = params.libraryCatalogItemId) => `/order-view/${orderId}/${catalogItemId}`, `Order View`, [locationBehaviors.requiresAuthentication]),
    ORDER_MANAGEMENT: createLocation(() => `/order-management`, `Manage Orders`, [locationBehaviors.requiresAuthentication]),
    SHIPPING_LOCATION_ADD: createLocation(() => `/shipping-location-add`, `Add Shipping Location`, [locationBehaviors.requiresAuthentication]),

    //Passwords
    FORGOT_PASSWORD: createLocation(() => `/forgot-password`, `Forgot Password`, [locationBehaviors.redirectToDashboard]),
    UPDATE_PASSWORD: createLocation((updatePasswordToken = params.updatePasswordToken) => `/update-password/${updatePasswordToken}`, `Update Password`, [locationBehaviors.redirectToDashboard]),

    //User
    USER_MANAGEMENT: createLocation(() => `/user-management`, `Manage Users`, [locationBehaviors.requiresAuthentication]),
    USER_PROFILE: createLocation((patronId = params.patronId) => `/user-profile/${patronId}`, `User Profile`, [locationBehaviors.requiresAuthentication]),

    //Student
    REGISTER_STUDENT: createLocation(() => `/register-student/new`, `Register Student`, [locationBehaviors.requiresAuthentication]),
    EDIT_STUDENT: createLocation((studentId = params.studentId) => `/edit-student/${studentId}`, `Edit Student`, [locationBehaviors.requiresAuthentication]),
    STUDENT_MANAGEMENT: createLocation(() => `/student-management`, `Manage Students`, [locationBehaviors.requiresAuthentication]),

    //Reports
    REPORTS: createLocation(() => `/reports`, `Reports`, [locationBehaviors.requiresAuthentication]),

    // Sad paths
    NOT_AUTHORIZED: createLocation(() => `/notAuthorized`, `Not Authorized`, [locationBehaviors.requiresAuthentication, locationBehaviors.doNotRedirectBack]),
    NOT_FOUND: createLocation(() => `/pageNotFound`, `Not Found`, [locationBehaviors.doNotRedirectBack]),

};

export const allLocations = createArrayFromObject(locations);

