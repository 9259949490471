import React from "react";
import PropTypes from "prop-types";
import {HtmlDisplay} from "../../Components/Display";
import {Button, ButtonLinkStyle} from "../../Components/Inputs";
import {GridCell, GridContainer, GridTable} from "../../Components/Grid";
import {Allow, policyEvents} from "../../Components/Authorize";
import {isGuidEmpty, isNullOrUndefined} from "../../Utilities/commonUtilities";
import {ORDER_TYPE} from "../Order/OrderRequest/orderRequestConstants";

export const LibraryCatalogItem = ({
                                       catalogId,
                                       description,
                                       details,
                                       formats,
                                       handleAddToQueue,
                                       handleDelete,
                                       handleEdit,
                                       handleOrderRequest,
                                       handleViewOrder,
                                       orderId,
                                       orderType,
                                       title,
                                   }) => {


    const handleClickEdit = (catalogId, format) => handleEdit(catalogId, format);
    const handleClickViewOrder = () => handleViewOrder(orderId, catalogId);
    const handleClickOrderRequest = (catalogId, catalogFormatId) => handleOrderRequest(catalogId, catalogFormatId);

    return <>
        <GridContainer className={`catalog__item`} hasMarginX>
            <GridCell>
                <h3 id={`title${catalogId}`}>
                    {title + " "}
                    {
                        <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                            <ButtonLinkStyle isCollapsed label={`(Edit Item)`}
                                             name={`btnEdit${catalogId}`}
                                             onClick={() => handleClickEdit(catalogId)}/>
                        </Allow>
                    }
                </h3>

                <HtmlDisplay html={description}/>

                <HtmlDisplay html={details}/>
                {
                    handleDelete && isNullOrUndefined(orderId) &&
                    <Button isCollapsed label={`Remove`} name={`btnDelete${catalogId}`} onClick={handleDelete}/>
                }
                {
                    handleViewOrder && !isNullOrUndefined(orderId) &&
                    <ButtonLinkStyle isCollapsed
                                     label={orderType === ORDER_TYPE.SpecialOrderRequest.toString() ? `(View Special Order)` : `View Order`}
                                     name={`btnViewOrder${catalogId}`}
                                     onClick={handleClickViewOrder}/>
                }

                {
                    formats &&
                    <GridTable>
                        <thead>
                        <tr>
                            <th>Format</th>
                            <th>Availability</th>
                            <th className={`text-center`} colSpan={2}>Actions</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                            formats.map(format => {
                                const {catalogFormatId, currentAvailability, formatName, numberOfCopiesAvailable, copies} = format;
                                const suffix = `${catalogFormatId}`;
                                const key = `${title}-${catalogFormatId}`;
                                const handleClickAddToQueue = () => handleAddToQueue(catalogFormatId);
                                const isEmptyGuid = isGuidEmpty(catalogFormatId);
                                const isAvailable =!isEmptyGuid && copies.length !== 0 && numberOfCopiesAvailable !== 0;
                                const isSpecialOrder = !isEmptyGuid && copies.length === 0;
                                const isOrder = !isEmptyGuid && copies.length !== 0 && numberOfCopiesAvailable === 0;

                                return <tr key={key}>
                                    <td>{formatName}</td>
                                    <td>{currentAvailability}</td>
                                    <td className={`text-center`}>
                                    {
                                        isAvailable &&
                                        <span>
                                            <ButtonLinkStyle isCollapsed isPrimary label={`Add to Queue`}
                                                             name={`btnAddQueue${suffix}`}
                                                             onClick={handleClickAddToQueue}/>
                                        </span>
                                    }
                                    {
                                        isSpecialOrder &&
                                        <span>

                                            <ButtonLinkStyle isCollapsed
                                                             isPrimary
                                                             label={`Special Order Request`}
                                                             name={`btnSpecialOrderRequest${suffix}`}
                                                             onClick={() => handleClickOrderRequest(catalogId, catalogFormatId)}/>
                                        </span>
                                    }
                                    {
                                        isOrder &&
                                        <span>
                                            <ButtonLinkStyle isCollapsed
                                                             isPrimary
                                                             label={`Order Request`}
                                                             name={`btnOrderRequest${suffix}`}
                                                             onClick={() => handleClickOrderRequest(catalogId, catalogFormatId)}/>
                                        </span>
                                    }
                                    <Allow policyEvent={policyEvents.MANAGE_CATALOG}>
                                        <span>
                                            &nbsp;| <ButtonLinkStyle isCollapsed label={`Edit Item`}
                                                             name={`btnEdit${catalogId}`}
                                                             onClick={() => handleClickEdit(catalogId, catalogFormatId)}/>
                                        </span>
                                    </Allow>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </GridTable>
                }

            </GridCell>
        </GridContainer>
    </>;
};

LibraryCatalogItem.propTypes = {
    description: PropTypes.string,
    details: PropTypes.string,
    catalogId: PropTypes.string,
    formats: PropTypes.array,
    handleAddToQueue: PropTypes.func,
    handleDelete: PropTypes.func,
    handleEdit: PropTypes.func,
    handleOrderRequest: PropTypes.func,
    handleViewOrder: PropTypes.func,
    orderId: PropTypes.string,
    orderType: PropTypes.string,
    title: PropTypes.string,
};