import React from "react";
import PropTypes from "prop-types";
import {Button, convertInputFormToObject, DateTimePickerField, SelectField} from "../../Components/Inputs";
import {GridCell, gridConstants, GridContainer} from "../../Components/Grid";

export const LibraryQueueForm = ({
                                     handleAssignQueueItems,
                                     loanForm,
                                     shippingLocations,
                                     students
                                 }) => {

    const handleClickSubmit = () => {
        const loanModel = convertInputFormToObject(loanForm);
        handleAssignQueueItems(loanModel);
    }

    return <>
        <GridContainer hasMarginX>

            <DateTimePickerField
                {...loanForm.needFrom}
                medium_columns={gridConstants.column.SIX}
                large_columns={gridConstants.column.THREE}
            />

            <DateTimePickerField
                {...loanForm.needTo}
                medium_columns={gridConstants.column.SIX}
                large_columns={gridConstants.column.THREE}
            />

            <SelectField
                {...loanForm.shippingLocationId}
                defaultOption={`Select Shipping Location`}
                medium_columns={gridConstants.column.SIX}
                large_columns={gridConstants.column.THREE}
                label={`Location`}
                options={shippingLocations}
            />

            <SelectField
                {...loanForm.studentId}
                defaultOption={`Select Student`}
                medium_columns={gridConstants.column.SIX}
                large_columns={gridConstants.column.THREE}
                label={`Student`}
                options={students}
            />
            <GridCell medium_offset={gridConstants.column.FOUR} medium_columns={gridConstants.column.FOUR}
                      large_offset={gridConstants.column.FIVE} large_columns={gridConstants.column.TWO}>
                <Button
                    isPrimary
                    label={`Submit`}
                    name={`btnSubmit`}
                    onClick={handleClickSubmit}
                />
            </GridCell>
        </GridContainer>
        <br/>
        <br/>
    </>;
};

LibraryQueueForm.propTypes = {
    handleAssignQueueItems: PropTypes.func.isRequired,
    loanForm: PropTypes.object.isRequired,
    shippingLocations: PropTypes.array.isRequired,
    students: PropTypes.array.isRequired,
};