import PropTypes from "prop-types";
import React from "react";
import {isInArray} from "../../../../Utilities/Types/arrayUtilities";
import {HtmlDisplay} from "../../../Display";
import {buttonIconType, buttonIconTypeList} from "./buttonIconConstants";

const ButtonIcon = ({disabled, inputDescription, iconType, isInputAction, id, onClick, children}) => {

	if (!iconType || !isInArray(buttonIconTypeList, iconType)) return <> </>;

	const actionClass = isInputAction ? "buttonIcon--inputAction" : "";
	const disabledClass = disabled ? "is-disabled" : "";

	return (
		<span className={`buttonIcon ${iconType} ${actionClass} ${disabledClass}`}>
            <button
				id={id}
	            className={`buttonIcon__button`}
	            disabled={disabled}
	            onClick={onClick}
				data-testid={id}
				type={`button`}
            >
				<HtmlDisplay html={inputDescription} className={`show-for-sr`} isInline />
            </button>
            <span aria-hidden={true} className="buttonIcon__flag"/>
			{children}
        </span>
	);
};

export const ButtonIconAdd = (props) => <ButtonIcon {...props} iconType={buttonIconType.ADD}/>;
export const ButtonIconAddOption = (props) => <ButtonIcon {...props} iconType={buttonIconType.ADD_OPTION}/>;
export const ButtonIconCancel = (props) => <ButtonIcon {...props} iconType={buttonIconType.CANCEL} />;
export const ButtonIconCertificate = (props) => <ButtonIcon {...props} iconType={buttonIconType.CERTIFICATE}/>;
export const ButtonIconCopyComponent = (props) => <ButtonIcon {...props} iconType={buttonIconType.COPY_COMPONENT}/>;
export const ButtonIconDelete = (props) => <ButtonIcon {...props} iconType={buttonIconType.DELETE}/>;
export const ButtonIconDeleteComponent = (props) => <ButtonIcon {...props} iconType={buttonIconType.DELETE_COMPONENT}/>;
export const ButtonIconDoneEditing = (props) => <ButtonIcon {...props} iconType={buttonIconType.DONE_EDITING}/>;
export const ButtonIconEdit = (props) => <ButtonIcon {...props} iconType={buttonIconType.EDIT}/>;
export const ButtonIconEnrollments = (props) => <ButtonIcon {...props} iconType={buttonIconType.ENROLLMENTS}/>;
export const ButtonIconFollowup = (props) => <ButtonIcon {...props} iconType={buttonIconType.DOWNLOAD_FOLLOWUP}/>;
export const ButtonIconImpersonate = (props) => <ButtonIcon {...props} iconType={buttonIconType.IMPERSONATE}/>;
export const ButtonIconContent = (props) => <ButtonIcon {...props} iconType={buttonIconType.CONTENT}/>;
export const ButtonIconMenu = (props) => <ButtonIcon {...props} iconType={buttonIconType.MENU}/>;
export const ButtonIconModules = (props) => <ButtonIcon {...props} iconType={buttonIconType.MODULES}/>;
export const ButtonIconOrder = (props) => <ButtonIcon {...props} iconType={buttonIconType.ORDER}/>;
export const ButtonIconProfile = (props) => <ButtonIcon {...props} iconType={buttonIconType.PROFILE}/>;
export const ButtonIconRemove = (props) => <ButtonIcon {...props} iconType={buttonIconType.REMOVE}/>;
export const ButtonIconSave = (props) => <ButtonIcon {...props} iconType={buttonIconType.SAVE}/>;
export const ButtonIconSearch = (props) => <ButtonIcon {...props} iconType={buttonIconType.SEARCH}/>;
export const InputClear = (props) => <ButtonIcon {...props} iconType={buttonIconType.CLEAR} isInputAction/>;
export const ButtonIconExit = (props) => <ButtonIcon {...props} iconType={buttonIconType.EXIT} isInputAction/>;
export const ButtonIconHistory = (props) => <ButtonIcon {...props} iconType={buttonIconType.HISTORY} isInputAction/>;
export const ButtonIconView = (props) => <ButtonIcon {...props} iconType={buttonIconType.VIEW}/>;
export const ButtonIconProgress = (props) => <ButtonIcon {...props} iconType={buttonIconType.PROGRESS}/>;
export const ButtonIconUpload = (props) => {
	const {onClick, name} = props;
	const fileName = `${name}_file`;
	const onButtonClick = () => document.getElementById(fileName).click();

	return <>
		<ButtonIcon {...props} iconType={buttonIconType.UPLOAD} onClick={onButtonClick}>
            {/* eslint-disable-next-line *//***********LINTER WRONG*************/}
			<label htmlFor={fileName}>
                <HtmlDisplay html={props.inputDescription} className={`show-for-sr`} isInline />
			</label>
			<input type="file"
			       data-testid={fileName}
			       name={fileName}
			       id={fileName}
			       onChange={onClick}
			/>
		</ButtonIcon>
	</>;
};

const sharedProps = {
	disabled: PropTypes.bool,
	id: PropTypes.string,
	inputDescription: PropTypes.string.isRequired,
	onClick: PropTypes.func.isRequired,
};

ButtonIconAdd.propTypes = sharedProps;
ButtonIconCertificate.propTypes = sharedProps;
ButtonIconContent.propTypes = sharedProps;
ButtonIconDelete.propTypes = sharedProps;
ButtonIconEdit.propTypes = sharedProps;
ButtonIconEnrollments.propTypes = sharedProps;
ButtonIconFollowup.propTypes = sharedProps;
ButtonIconImpersonate.propTypes = sharedProps;
ButtonIconMenu.propTypes = sharedProps;
ButtonIconModules.propTypes = sharedProps;
ButtonIconOrder.propTypes = sharedProps;
ButtonIconRemove.propTypes = sharedProps;
ButtonIconProfile.propTypes = sharedProps;
ButtonIconSearch.propTypes = sharedProps;
InputClear.propTypes = sharedProps;
ButtonIconExit.propTypes = sharedProps;
ButtonIconHistory.propTypes = sharedProps;
ButtonIconView.propTypes = sharedProps;
ButtonIconProgress.propTypes = sharedProps;
ButtonIconUpload.propTypes = {
	...sharedProps,
	name: PropTypes.string.isRequired
};

ButtonIcon.propTypes = {
	...sharedProps,
	children: PropTypes.any,
	iconType: PropTypes.string.isRequired,
	isInputAction: PropTypes.bool,
};