import {createListFromObject} from "../../Utilities/Types/listUtilities";

export const LOAN_STATUS = {
    "ApproveForCheckout": 0,
    "ReadyForCheckout": 1,
    "GetFromShelf": 2,
    "PrintMailingLabel": 3, //not status
    "ReadyToMail": 4, //show print label button
    "Mailed": 5,
    "Returned": 6,
};

export const LOAN_STATUS_LIST = createListFromObject(LOAN_STATUS);
