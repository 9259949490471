import {config, requestOptions, requestTypes, sendRequest} from "../../../Utilities/Api";

//************************************************** STANDARD API SERVER CALLS ****************************************
class serverOrderManagementApi {
    static getOrders(orderSearchCriteria) {
        const queryParameters = new URLSearchParams(orderSearchCriteria).toString();
        return sendRequest(
            requestOptions(`${config.URL}orders?${queryParameters}`,
                requestTypes.GET,
            )
        );
    }
}

const orderManagementApi = config.environment.MODE === config.modes.SERVER ? serverOrderManagementApi : serverOrderManagementApi;
export default orderManagementApi;