import {
    Button,
    checkAndSetError,
    clearInputFormErrorFields, convertInputFormToObject,
    InputForm, inputFormIsValid,
    TextField,
    useInputForm
} from "../../../Components/Inputs";
import {GridCell, gridConstants} from "../../../Components/Grid";
import React from "react";
import {shippingLocationModel} from "./shippingLocationFactory";
import PropTypes from "prop-types";

export const ShippingLocationAddForm = ({
                                            handleReturn,
                                            handleSaveShippingLocation,
                                        }) => {

    const shippingLocationForm = useInputForm(shippingLocationModel());

    const handleSaveClick = () => {

        clearInputFormErrorFields(shippingLocationForm);

        checkAndSetError([
            shippingLocationForm.name,
            shippingLocationForm.address,
            shippingLocationForm.city,
            shippingLocationForm.state,
            shippingLocationForm.zipcode,
        ]);

        if (inputFormIsValid(shippingLocationForm)) {
            const shippingLocation = convertInputFormToObject(shippingLocationForm);
            handleSaveShippingLocation(shippingLocation);
        }
    }


    return <InputForm name={`shippingLocationForm`}>
        <TextField
            {...shippingLocationForm.name}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...shippingLocationForm.address}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...shippingLocationForm.city}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...shippingLocationForm.state}
            medium_columns={gridConstants.column.SIX}/>

        <TextField
            {...shippingLocationForm.zipcode}
            medium_columns={gridConstants.column.SIX}/>

        <GridCell className={`text-center`}>
            <Button
                isPrimary isCollapsed
                label={'Save'}
                name={`btnSave`}
                onClick={handleSaveClick}/>
            <Button
                isCollapsed
                label={'Cancel'}
                name={`btnCancel`}
                onClick={handleReturn}/>
        </GridCell>
    </InputForm>;
};

ShippingLocationAddForm.propTypes = {
    handleReturn: PropTypes.func.isRequired,
    handleSaveShippingLocation: PropTypes.func.isRequired,
}