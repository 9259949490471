import PropTypes from "prop-types";
import {PageTitle} from "../../../Components/Landmarks";
import React, {useEffect, useState} from "react";
import {isFimcAdmin} from "../../../Utilities/LocalStorage/storageUtilities";
import {order} from "./orderManagementFactory";
import orderManagementApi from "./orderManagementApi";
import OrderManagementView from "./OrderManagementView";
import OrderSearchFormView from "./OrderSearchFormView";
import {gridConstants} from "../../../Components/Grid";
import OrderRequestApi from "../OrderRequest/orderRequestApi";
import {createListFromArrayOfObjects} from "../../../Utilities/Types/listUtilities";
import {isNullOrUndefined} from "../../../Utilities/commonUtilities";

export const OrderManagementContainer = ({
                                             districts,
                                             handleApiCall,
                                             handleSetOrderSearchCriteria,
                                             isLoading,
                                             orderSearchCriteria,
                                             redirect,
                                         }) => {

        const [orders, setOrders] = useState([]);
        const [shippingLocations, setShippingLocations] = useState();
        const filteredOrders = orderSearchCriteria !== null && orderSearchCriteria.ApplySorting(orderSearchCriteria, orders);

        const buttonSharedSettings = {
            medium_columns: gridConstants.column.THREE,
            small_columns: gridConstants.column.SIX,
        };

        const loadOrderResults = (result) => {
            const orders = result.map(order);
            setOrders(orders);
        };

        const loadShippingLocationResult = (results) => {
            setShippingLocations(createListFromArrayOfObjects(results, `shippingLocationId`, `name`));
        }

        useEffect(() => {

            if (isNullOrUndefined(districts) || districts.length === 0) return;

            const getShippingLocations = () => {
                handleApiCall(() => OrderRequestApi.getShippingLocations(), loadShippingLocationResult);
            }

            if (!shippingLocations)
                getShippingLocations();

            if (orderSearchCriteria.loadSearchResults) {
                if (isFimcAdmin())
                    handleApiCall(() => orderManagementApi.getOrders({
                        ...orderSearchCriteria,
                    }), loadOrderResults);
            }

        }, [districts, orderSearchCriteria]);

        if (!orderSearchCriteria) return null;

        return <>

            <PageTitle h1={`Manage Orders`}/>
            {
                shippingLocations &&
                <OrderSearchFormView
                    buttonSharedSettings={buttonSharedSettings}
                    districts={districts}
                    handleSetOrderSearchCriteria={handleSetOrderSearchCriteria}
                    isLoading={isLoading}
                    orderSearchCriteria={orderSearchCriteria}
                    shippingLocations={shippingLocations}
                />
            }

            <OrderManagementView
                handleSetOrderSearchCriteria={handleSetOrderSearchCriteria}
                orderSearchCriteria={orderSearchCriteria}
                orders={filteredOrders}
                redirect={redirect}
            />
        </>;
    }
;

OrderManagementContainer.propTypes = {
    districts: PropTypes.array,
    handleApiCall: PropTypes.func.isRequired,
    handleSetOrderSearchCriteria: PropTypes.func.isRequired,
    isLoading: PropTypes.bool.isRequired,
    redirect: PropTypes.func.isRequired,
    orderSearchCriteria: PropTypes.object,
};