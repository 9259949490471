import {filterObjectArrayByAllFields} from "../../Utilities/Types/arrayUtilities";
import {getTodayDateAsString, isDateBetween} from "../../Utilities/Types/dateUtilities";
import {getTextByValue} from "../../Utilities/Types/listUtilities";
import {COPY_STATUS_LIST, FORMAT_LIST, GRADE_LEVEL_LIST, QUEUE_STATUS_LIST} from "./libraryConstants";

export function filterCatalog(resources, keywords) {
    return filterObjectArrayByAllFields(resources, keywords);
}

export function filterCatalogWhatsNew(catalog=[]) {
    const today = getTodayDateAsString();
    return catalog.filter(c => isDateBetween(today, c.whatsNewStartDate, c.whatsNewEndDate));
}

export const getCopyStatusTextByValue = (value="") => getTextByValue(COPY_STATUS_LIST, value);

export const getFormatTextByValue = (value="") => getTextByValue(FORMAT_LIST, value);

export const getGraveLevelTextByValue = (value="") => getTextByValue(GRADE_LEVEL_LIST, value);

export const getQueueStatusTextByValue = (value="") => getTextByValue(QUEUE_STATUS_LIST, value);
